<template>
    <div :class="$style.container">
    <!-- 面包屑导航 -->
    <Breadcrumb :breadList="breadList">
        <a-button class="add-bt" type="primary"  @click="add()">新增</a-button>
    </Breadcrumb>
    <a-form ref="form" class="al-form" layout="inline" :model="form" :label-col="{ style: 'width: 80px' }" :wrapper-col="{ style: 'width: 170px' }">
      <a-form-item label="名称:">
        <a-input v-model="form.name" placeholder="请输入内容" />
      </a-form-item>

      <a-button type="primary"
                :loading="isQueryLoading"
                @click="queryList">查询</a-button>
      <a-button @click="resetForm('form')"
                style="margin-left:20px;">重置</a-button>
    </a-form>

    <a-table :data-source="list"
             bordered
             :rowKey="record => record.id"
             :columns="columns"
             :pagination="pagination">
        <div slot="action1" slot-scope="scope">
          <span style="color:#1890ff;">{{scope}}</span>
        </div>
         <div slot="action2" slot-scope="scope">
          <a  @click="zoom(scope)">
          <a-avatar shape="square"
                style="width:40px;height:40px;"
                :src="scope"/>
          </a>
        </div>
        <div slot="action" slot-scope="text,record">
            <a-space>
              <a @click="edit(record)" ><a-spin size="small" v-if="isLoading" />编辑</a>
              <a-divider type="vertical"/>
              <a-popconfirm
                title="确定要删除吗？"
                ok-text="是"
                cancel-text="否"
                @confirm="confirm(record)"
                @cancel="cancel"
              >
                <a href="#">删除</a>
              </a-popconfirm>
            </a-space>
        </div>
    </a-table>
    <a-modal
      :footer="null"
      :visible="visibleImag"
      @ok="handleOk1"
      @cancel="handleCancel1"
    >
      <img style="width:100%" :src="advertlImag"/>
    </a-modal>
    <!-- 编辑广告弹窗 -->
    <a-modal
        :title="modalTitle"
      :footer="null"
      :visible="visible"
      @ok="handleOk"
       @cancel="handleCancel"
    >
        <AddOrEdit  @ok="handleOk" @cancel="handleCancel" ref="modalRef"></AddOrEdit>
    </a-modal>
  </div>
</template>

<script>
import Breadcrumb from '@/components/breadcrumb.vue'
import AddOrEdit from './dialog/addOrEdit.vue'
export default {
  components: { Breadcrumb, AddOrEdit },
  data () {
    return {
      breadList: [
        {
          name: '广告管理',
          url: ''
        },
        {
          name: '广告列表',
          url: ''
        }
      ],
      advertlImag: '',
      visibleImag: false, // modal
      modalTitle: '添加广告',
      isLoading: false, // 编辑状态
      visible: false, // modal
      isQueryLoading: false,
      form: {
        name: ''
      },
      columns: [
        { title: '广告名称', key: 'name', dataIndex: 'name', scopedSlots: { customRender: 'action1' } },
        { title: '编号', key: 'code', dataIndex: 'code' },
        { title: '图片', key: 'imageUrl', dataIndex: 'imageUrl', scopedSlots: { customRender: 'action2' } },
        { title: '打开方式', key: 'intent', dataIndex: 'intent' },
        { title: '资源类型', key: 'resourceType', dataIndex: 'resourceType' },
        { title: '资源', key: 'identifier', dataIndex: 'identifier' },
        { title: '操作', key: 'action', dataIndex: 'action', scopedSlots: { customRender: 'action' } }
      ],
      pagination: {
        total: 0,
        current: 1,
        pageSize: this.$enums.Pagination.pageSize,
        showLessItems: false,
        showSizeChanger: true,
        showQuickJumper: true,
        pageSizeOptions: this.$enums.Pagination.pageSizes,
        showTotal: (total, range) => `共 ${total} 条记录 第 ${range[0]}-${range[1]} 条数据`,
        onChange: pageNo => this.pageNoChange(pageNo),
        onShowSizeChange: (pageNo, pageSize) => this.pageSizeChange(pageNo, pageSize)
      },
      total: 0,
      list: []
    }
  },
  created () {
    this.reloadList()
  },
  mounted () {
  },
  destroyed () {
  },
  computed: {
  },
  methods: {
    add () {
      this.modalTitle = '添加广告'
      this.visible = true
      this.$nextTick(() => {
        this.$refs.modalRef.init()
      })
    },
    edit (record) {
      this.modalTitle = '编辑广告'
      this.visible = true
      this.$nextTick(() => {
        this.$refs.modalRef.init(record)
      })
    },
    handleOk (e) {
      setTimeout(() => {
        this.visible = false
      })
      this.reloadList()
    },
    handleCancel (e) {
      try {
        this.$refs.modalRef.$refs.form.clearValidate()
      } catch (error) {
        console.log('refs form还未加载')
      }
      this.visible = false
    },
    handleOk1 (e) {
      setTimeout(() => {
        this.visibleImag = false
      })
      this.reloadList()
    },
    handleCancel1 (e) {
      this.visibleImag = false
    },
    zoom (scope) {
      this.visibleImag = true
      this.advertlImag = scope
    },
    cancel () {},
    // 删除广告
    confirm (record) {
      this.del(record)
    },
    async del (record) {
      record.isLoading = true
      const res = await this.$store.dispatch('http', {
        api: 'deleteAdvert',
        query: {
          id: record.id
        }
      })
      if (res) {
        record.isLoading = false
        this.$store.dispatch('showToast', { message: '删除成功~' })
        this.queryList()
      }
    },
    // 重置表单
    resetForm () {
      this.form = {
        name: ''
      }
      this.queryList()
    },
    // 查询列表
    queryList () {
      this.pagination.current = 1
      this.isQueryLoading = true
      this.reloadList(() => {
        this.isQueryLoading = false
      })
    },
    // 重新加载列表（参数初始化）
    reloadList (callback = () => { }) {
      this.loadList(callback)
    },
    // 加载列表
    async loadList (callback = () => { }) {
      this.isTableLoading = true
      const params = Object.assign({
        pageNo: this.pagination.current,
        pageSize: this.pagination.pageSize
      }, this.form)
      const { total, records } = await this.$store.dispatch('http', {
        api: 'advertPage',
        params,
        complete: () => {
          this.isTableLoading = false
          callback()
        }
      })
      this.pagination.total = total
      if (records) {
        records.forEach(item => {
          item.isLoading = false
        })
      }
      this.list = records
    },
    // 页码切换
    pageNoChange (pageNo) {
      this.pagination.current = pageNo
      this.loadList()
    },
    // 每页显示条数切换
    pageSizeChange (pageNo, pageSize) {
      this.pagination.pageSize = pageSize
      this.pagination.current = pageNo
      this.reloadList()
    }
  }
}
</script>

<style lang="scss" module>
.container {
  width: auto;
}
</style>
