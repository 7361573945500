<template>
  <a-form-model
    ref="form"
    :rules="rules"
    :label-col="{ span: 6 }"
    :wrapper-col="{ span: 15 }"
    :model="form"
  >
    <a-form-model-item label="名称:" prop="name">
      <a-input v-model="form.name" type="string" placeholder="请输入" />
    </a-form-model-item>
    <a-form-model-item label="编号:" prop="code">
      <a-input v-model="form.code" type="string" placeholder="请输入" />
    </a-form-model-item>
    <a-form-model-item label="文章ID:" prop="identifier">
      <a-input v-model="form.identifier" type="string" placeholder="请输入小于4位数字" />
    </a-form-model-item>
    <a-form-model-item label="广告图:" prop="imageUrl">
        <ImageManage v-model="form.imageUrl" @change="$refs.form.validateField(['imageUrl'])" :square="false" :size="1024*1024*2" :showWidth="true">
            <div slot="extra">请上传高清正面主图，提高用户辨识度和转化率，建议尺寸宽375像素，建议大小在500KB以内</div>
        </ImageManage>
    </a-form-model-item>

    <a-form-model-item
      :colon="false"
      label=" "
      :label-col="{ span: 6 }"
      :wrapper-col="{ span: 8 }"
    >
      <a-space>
        <a-button @click="cancel"> 取消 </a-button>
        <a-button @click="determine" type="primary">
          {{ form.id ? "编辑" : "添加" }}</a-button
        >
      </a-space>
    </a-form-model-item>

    <br />
  </a-form-model>
</template>

<script>
import ImageManage from '@/components/imageManage/index.vue'
export default {
  components: { ImageManage },
  data () {
    return {
      disabledStatus: false,
      loading1: false,
      btCount: 0,
      overSize: true,
      uploadStatus: true,
      zanshiFile: '',
      orgTypeList: [
        {
          value: 'team',
          name: '团队'
        }
      ], // 团队列表
      isDetermineLoading: false, // 是否显示确定loading
      form: {},
      rules: {
        grade: undefined,
        name: [
          {
            required: true,
            message: '广告名称不能为空',
            trigger: 'change'
          }
        ],
        code: [
          {
            required: true,
            message: '广告编号不能为空',
            trigger: 'change'
          }
        ],
        imageUrl: [
          {
            required: true,
            message: '请上传广告图',
            trigger: 'change'
          }
        ],
        identifier: [
          {
            required: true,
            message: '文章ID不能为空',
            trigger: 'change'
          }
        ]
      }
    }
  },
  created () {},
  mounted () {},
  computed: {},
  methods: {
    resetForm () {
      this.form = {
        id: '',
        name: '',
        code: '',
        imageUrl: '',
        intent: 'open',
        resourceType: 'content',
        identifier: ''
      }
    },
    cancel () {
      this.$refs.form.resetFields()
      this.$refs.form.clearValidate()
      this.form = {
        id: '',
        name: '',
        code: '',
        imageUrl: '',
        intent: 'open',
        resourceType: 'content',
        identifier: ''
      }
      this.$emit('cancel')
    },
    async init (
      item = {
        id: '',
        name: '',
        code: '',
        imageUrl: '',
        intent: 'open',
        resourceType: 'content',
        identifier: ''
      }
    ) {
      const {
        id,
        name,
        code,
        imageUrl,
        intent,
        resourceType,
        identifier
      } = item
      this.form = {
        id,
        name,
        code,
        imageUrl,
        intent,
        resourceType,
        identifier
      }
    },
    // 提交保存
    async determine (e) {
      if (!this.btCount) {
        this.goComfirm()
        this.btCount++
      }
      setTimeout(() => {
        this.btCount = 0
      }, 1000)
    },
    async goComfirm (e) {
      this.$refs.form.validate(async (valid) => {
        if (valid) {
          const params = this.$utils.clone(this.form)
          let api = 'addAdvert'
          if (params.id) {
            api = 'editAdvert'
          }
          await this.$store.dispatch('http', {
            api,
            params,
            complete: () => {
              this.isDetermineLoading = false
            }
          })
          this.$store.dispatch('showToast', {
            message: (api === 'editTeam' ? '编辑' : '新增') + '广告成功'
          })
          this.$emit('ok')
        }
      })
    }
  }
}
</script>

<style lang="scss" module>
.container {
  width: auto;
}
</style>
